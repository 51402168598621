@import './config';
@import './mixins';

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

#__next {
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  line-height: 1.15;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  background: $color-white;
  color: $color-charcoal;
  font-family: $font-family-serif;
  font-size: $font-size-sm;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.25;
  margin: 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

b,
strong {
  font-family: $font-family-sans-serif;
  font-weight: normal;
}

img {
  border-style: none;
}

button,
input,
select,
textarea {
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

@include cdn-font-face(ov-gothic, ov-gothic, normal, normal);
@include cdn-font-face(ov-gothic, ov-gothic, normal, bold);
@include cdn-font-face(merlo, merlo, normal, normal);
@include cdn-font-face(merlo, merlo, normal, bold);
@include cdn-font-face(merlo, merlo-italic, italic, normal);
@include cdn-font-face(merlo, merlo-italic, italic, bold);

a {
  background-color: transparent;
  color: $color-charcoal;
  text-decoration: underline;
  transition: color .2s ease-in-out, background-color .2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: $color-hover;
    text-decoration: none;
  }
}

em {
  font-family: $font-family-serif;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-sans-serif;
  font-weight: bold;
}

h1 {
  font-size: $font-size-xxl;
}

h2 {
  font-size: $font-size-xl;
}

h3 {
  font-size: $font-size-lg;
}

h4 {
  font-size: $font-size-md;
}

h5 {
  font-size: $font-size-sm;
}

h6 {
  font-size: $font-size-xsm;
}

::selection {
  background: $color-gray-xlight;
  color: $color-ov-blue;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.lazyloaded {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.ls-is-cached {
  opacity: 1;
}
