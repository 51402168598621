.rdw-option-active {
  background-color: #f1f1f1;
}

.rdw-option-disabled {
  opacity: .2;
}

.rdw-link-modal {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 24px 6px rgba(32, 32, 32, .1);
  display: flex;
  flex-direction: column;
  left: 6px;
  padding: 12px;
  position: absolute;
  bottom: 36px;
  width: 235px;
  z-index: 100;
}

.rdw-link-modal-label {
  font-size: 14px;
}

.rdw-link-modal-input {
  background: #f1f1f1;
  border: 0;
  height: 36px;
  margin: 6px 0 12px;
  padding: 0 12px;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 12px;
}

.rdw-link-modal-target-option > span {
  margin-left: 6px;
}

.rdw-link-modal-btn {
  background: #202020;
  border: 0;
  border-radius: 18px;
  color: #fff;
  cursor: pointer;
  height: 36px;
  margin-left: 10px;
  padding: 0 18px;
  text-transform: capitalize;
}

.rdw-link-modal-btn:hover {
  background: #000f9f;
}

.rdw-link-modal-btn:active {
  position: relative;
  top: 1px;
}

.rdw-link-modal-btn:disabled {
  background: #f1f1f1;
  color: #d2d2d2;
}

.public-DraftStyleDefault-block {
  font-family: 'merlo', georgia, serif;
  font-size: 16px;
  margin: 24px 0;
}

.public-DraftStyleDefault-block:first-child {
  margin-top: 0;
}

.public-DraftStyleDefault-block:last-child {
  margin-bottom: 16px;
}

.public-DraftStyleDefault-block strong {
  font-family: 'ov-gothic', 'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'Helvetica Neue', arial, sans-serif;
}

.public-DraftStyleDefault-block em {
  font-family: 'merlo-italic', georgia, serif;
}
